

































































































import mixins from 'vue-typed-mixins'
import Navigation from '@/builder/sections/mixins/Navigation'
import AdditionalPagesMenu from '@/builder/sections/navbar/components/AdditionalPagesMenu.vue'
import LoggedUserMenu from '@/builder/sections/navbar/components/LoggedUserMenu.vue'
import DefaultButton from '@/builder/sections/section/components/DefaultButton.vue'

export default mixins(Navigation).extend({
  name: 'FlexibleNavbar1',
  components: { DefaultButton, LoggedUserMenu, AdditionalPagesMenu }
})
